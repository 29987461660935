<script>
import BlockCard from '@/components/form-bloks/BlockCard.vue';

export default {
  props: {
    step: {
      type: [String, Number],
      required: true,
    },
    childProps: {
      type: Object,
      default: () => ({}),
    },
  },
  render(h) {
    return this.childProps
      ? h(BlockCard, {
        props: {
          ...this.childProps,
          specialImageClass: this.step.toString().startsWith('10') ? 'special-block-image' : '',
        },
      })
      : null;
  },
};
</script>

<style>
.step-block-title {
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  padding-top: 5px;
  color: #1A1919;
}
.step-block-description {
  font-size: 14px;
  color: #1A1919CC;
}
.step-block-description strong {
  color: #1A1919;
}
.block-image {
  width: 165px;
  height: 170px;
  margin-top: -30px;
  margin-bottom: -10px;
}
.special-block-image {
  margin-top: 0;
}

@media (max-width: 768px) {
  .step-block-title {
    font-size: 14px;
    line-height: 20px;
    padding-top: 18px;
  }
  .step-block-description {
    font-size: 12px;
    line-height: 18px;
    padding-bottom: 5px;
  }
  .block-image {
    width: 134px;
    height: 138px;
    margin-top: 10px;
  }
}
</style>
