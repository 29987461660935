import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import * as LDClient from 'launchdarkly-js-client-sdk';
import content from './store/content';
import auth from './store/auth';
import localization from './store/localization';
import { hideZendeskChatBot } from './helpers/zendesk-chatbot-handler';

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    step: '',
    prevStep: '',
    endpoints: {
      all: '/endpointsV2/SEP_V4.json',
      check_username: '/SEPv4_check_avail.html',
      heartbeat: '/ajax_heartbeat.html'
    },
    display: null,
    questions: null,
    num_req_in_major_step: 0,
    this_one_in_major_step: 0,
    reviewing_step: null,
    errorMessage: '',
    app_error: false,
    saved_email: '',
    use_email_as_username: true,
    popup_body: '',
    popup_title: '',
    show_popup_modal: false,
    is_first_step: false,
    disable_scroll_to_top: false,
    user_id: null,
    class_id: null,
    is_cys: null,
    enrollment_data_id: null,
    shortcut_URL: null,
    billing_type: null,
    user_type: null,
    corp_client_id: null,
    featureFlags: {},
    contentfulContent: {
      entries: {},
      error: false,
    },
    locale: null,
    jwt_token: null,
    multiple_accounts: false,
  },
  getters: {
    eatology(){
      return ['www.learneatology.com','learneatology.com','eatology.2score.com'].includes(window.location.hostname);
    },
    formatted_address(state){
      if (state.questions.find( question => question.name === 'ship_address1')) {
        let line1 = state.questions.find(question => question.name === 'ship_address1').value;
        let line2 = state.questions.find(question => question.name === 'ship_address2').value;
        let city = state.questions.find(question => question.name === 'ship_City').value;
        let provinceOrState = state.questions.find(question => question.name === 'ship_State').value;
        let postalOrZip = state.questions.find(question => question.name === 'ship_Zip').value;

        let address = line1;
        if (line2) {
          address += " " + line2;
        }
        address += ".<br/>";
        address += city + ", " + provinceOrState + " " + postalOrZip;
        return address;
      }
      return '';
    },
  },
  mutations: {
    update_corp_client_id: (state, e) => {
      state.corp_client_id = e
    },
    update_app_error: (state, e) => {
      state.app_error = e
    },
    update_scroll_to_top: (state, value) => {
      state.disable_scroll_to_top = value
    },
    update_use_email_as_username: (state, value) => {
      state.use_email_as_username = value
    },
    save_email: (state, email) => {
      state.saved_email = email
    },
    update_reviewing_step: (state, step) => {
      state.reviewing_step = step
    },
    update_error_message: (state, errorMessage) => {
      state.errorMessage = errorMessage
    },
    change_step: (state, val) => {
      state.step = val
    },
    empty_questions: (state) => {
      state.questions = []
    },
    multiple_accounts: (state, value) => {
      state.multiple_accounts = value
    },
    update_store: (state, data) => {
      // WON-1725
      state.is_first_step = data.is_first_step === true
      state.step = data.step
      state.prevStep = data.prevStep
      state.display = data.display
      state.questions = data.questions
      state.num_req_in_major_step = data.num_req_in_major_step
      state.this_one_in_major_step = data.this_one_in_major_step
      state.errorMessage = ''
      if(data.user_email){
        state.saved_email = data.user_email;
      }
      if (data.Popup_Body && data.Popup_Body !== '') {
        state.popup_body = data.Popup_Body
        state.popup_title = data.Popup_Title
        state.show_popup_modal = true
      }
      if (data.dialogs_user_id > 0){
        state.user_id = data.dialogs_user_id;
      }
      if (data.corp_client_id > 0){
        state.corp_client_id = data.corp_client_id;
      }
      if (data.class_id > 0){
        state.class_id = data.class_id;
      }
      if(data.is_cys){
        state.is_cys = data.is_cys;
      }
      if(data.enrollment_data_id){
        state.enrollment_data_id = data.enrollment_data_id;
      }
      if(data.shortcut_URL){
        state.shortcut_URL = data.shortcut_URL;
      }
      if(data.billing_type){
        state.billing_type = data.billing_type;
      }
      if(data.userType){
        state.user_type = data.userType;
      }
      if(data.jwt_token){
        state.jwt_token = data.jwt_token;
      }
    },
    update_question (state, question) {
      if (state.questions.filter(q => q.name == question.name).length > 0) { // the question exists in the array
        state.questions.map(q => {
          return (q.name == question.name) ? question : q
        })
      } else { // question is not in the array: add it to the questions array
        state.questions.push(question)
      }
    },
    update_value (state, payload) {
      // payload should have value and name attributes
      state.questions = state.questions.map(x => {
        if (x.name == payload.name) {
          x.value = payload.value
        }
        return x
      })
    },
    update_feature_flags (state, data) {
      state.featureFlags = data;
    },
    update_questions(state, payload) {
        state.questions = state.questions.map(question => {
            const questionItem = payload.find(item => item.name === question.name)
            if(questionItem) {
                question.label = questionItem.label
                question.options = questionItem.options
                question.validation_message = questionItem.validation_message
                question.content_tag_mapping = questionItem.content_tag_mapping
            }
            return question;
        });
    },
    update_contentful_content(state, payload) {
        state.contentfulContent = payload
    },
    update_locale(state, payload) {
        state.locale = payload
    },
  },
  actions: {
    initializeApp (context, payload = {}) {
      context.commit('update_app_error', false)
      return new Promise((resolve, reject) => {
        const regex = new RegExp('[\\?&]s=([^&#]*)');
        const results = regex.exec(location.search);
        const corporateShortcutURL = results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
        axios.post(context.state.endpoints.all, {
          'action': 'initialize',
          's': corporateShortcutURL,
          'locale': payload.locale,
          'hash': payload.hash,
        }).then(r => {
          if (r.data && r.data.success) {
            context.commit('update_store', r.data)
            const userId = r.data.dialogs_user_id || 'no-user'
            context.dispatch('initializeLaunchDarkly', userId);
            if (!!r.data.dialogs_user_id) {
                context.dispatch('getToken');
            }
            resolve(r.data)
          }
          reject()
        }).catch(e => {
          if (e.response.status == 400) {
            context.commit('update_app_error', true)
            context.commit('update_error_message', e.response.data.errorMessage)
            if (e.response.data && e.response.data.corp_client_id > 0){
              context.commit('update_corp_client_id', e.response.data.corp_client_id);
            }
            reject()
          } else if ((e.response.status == 302)&&(e.response.data.redirect_url!='')) {
            window.location.href = e.response.data.redirect_url;
          } else if (e.response && e.response.data && e.response.data.errorMessage === "Bad Request. Missing C_Corporate_Client."){
            window.location.replace('https://wondrhealth.com'); // redirect away from sep if not corp client is found
          } else {
              context.commit('update_app_error', true)
              console.error('There was an error with the request')
              console.log(e)
              reject(e)
          }
        })
      })
    },

    resetPassword (context) {
      context.commit('update_app_error', false)
      return new Promise((resolve, reject) => {
        let payload = {
          action: 'lost_PW',
          user_email: context.state.saved_email
        }
        axios.post(context.state.endpoints.all, payload).then(r => {
          if (r.data && r.data.success) {
            // console.log(r);
            resolve()
          }
        }).catch(e => {
          context.commit('update_app_error', true)
        })
      })
    },

    login (context, payload) {
      context.commit('update_app_error', false)
      return new Promise((resolve, reject) => {
        payload.action = 'login'
        axios.post(context.state.endpoints.all, payload).then(r => {
          if (r.data && r.data.success) {
            console.log('Logged in')
            // console.log(r);
            context.commit('update_store', r.data)
            resolve()
          }
        }).catch(e => {
          if (e.response.status == 401) {
            resolve('unauthorized')
          } else if (e.response.status == 400) {
            context.commit('update_app_error', true)
            context.commit('update_error_message', e.response.data.errorMessage)
          } else if ( (e.response.status == 302) && (e.response.data.redirect_url!='') ) {
            window.location.href = e.response.data.redirect_url;
          } else {
            context.commit('update_app_error', true)
          }
        })
      })
    },

    goto (context, payload) {
      context.commit('update_app_error', false)
      context.commit('update_scroll_to_top', true)
      return new Promise((resolve, reject) => {
        axios.post(context.state.endpoints.all, {
          action: 'goto',
          goto_step: payload.step,
          locale: payload.locale,
          hash: payload.step,
        }).then(r => {
          if (r.data && r.data.success) {
            // context.commit('update_store',r.data);
            resolve(r.data)
          } else {
            reject()
          }
        }).catch(e => {
          reject(e)
        })
      })
    },

    submitData (context, payload) {
      context.commit('update_app_error', false)
      context.commit('update_scroll_to_top', false)
      return new Promise((resolve, reject) => {
        payload.action = 'save_next'
        payload.step = context.state.step <= 10.5 ? 10 : context.state.step
        axios.post(context.state.endpoints.all, payload).then(r => {
          // console.log(r.data);
          if (r.data && r.data.success) {
            if (r.data.redirect_url) {
              // console.log('redirecting');
              // console.log(r.data.redirect_url);
              // SEP completed
              console.log('SEP completed');
              hideZendeskChatBot();
              let dataLayerPayload = {
                'event': 'trackEvent',
                'eventDetails.category': 'user', // static
                'eventDetails.action': 'enroll', // static
                'eventDetails.label': r.data.dialogs_user_id, // USER ID
                'userType': r.data.userType, // 'retail', 'corporate'
                'corpID': r.data.corpID // company name/id/shortcut
              };
              console.log("r:" + r.data.dialogs_user_id);

              // Pushing dataLayer event
              if (r.data.dialogs_user_id > 0) {
                console.log("userid found");
                console.log("dataLayer pushed: ", dataLayerPayload);
                dataLayer.push(dataLayerPayload);
              } else {
                console.error("dataLayer didn't push. Dialogs user id not found.");
              }
            }
            // console.log(r);
            // context.commit('update_store',r.data);
            resolve(r.data)
          } else {
            // console.error('There was an error with the response. Flag 55');
            if (r.data.errorCode == 400) {
              context.commit('update_error_message', r.data.errorMessage)
              reject()
            } else if (r.data.errorCode == 406) {
              // there request was unsuccessful. The backend tells us the reason through 406 codes
              let error_set = false;
              if ( r.data.errorMessage == 'Authentication failed.' ){
                context.commit('update_error_message', 'Sorry we couldn\'t validate those credentials in our system.')
                error_set = true
                reject()
                // WON-927 2021-10-15 added option for custom fail msg
              } else if (r.data.errorMessage.substr(0,22) == 'Authentication failed:') {
                context.commit('update_error_message', r.data.errorMessage.substr(22))
                error_set = true
                reject()

              }
              if ( r.data.errorMessage.startsWith('Error: ') ){
                error_set = true
                reject(r.data.errorMessage)
              }
              if ( !error_set ) {
                context.commit('update_error_message', 'There was an error. Please try again.')
                reject()
              }
            } else {
                context.commit('update_app_error', true)
                context.commit('update_error_message', r.data.errorMessage);
                reject();
            }
          }
        }).catch(e => {
          context.commit('update_app_error', true)
          console.error('There was an error with the request. Code 64.')
          console.log(e)
          reject(e)
        })
      })
    },

    saveFromModal (context, payload) {
      context.commit('update_app_error', false)
      return new Promise((resolve, reject) => {
        payload.action = 'save_from_modal'
        axios.post(context.state.endpoints.all, payload).then(r => {
          if (r.data && r.data.success) {
            // console.log(r);
            // context.commit('update_store',r.data);
            resolve()
          } else {
            // console.error('There was an error with the response');
            console.error(r)
            reject()
          }
        }).catch(e => {
          // We need to catch redirects becasue that's how users are redirected to the right
          // landing page after successfully completing an application
          if (e.response.status == 400) {
            // console.log(e.response);
            context.commit('update_error_message', e.response.data.errorMessage)
            resolve()
          } else {
            context.commit('update_app_error', true)
            // console.error('There was an error with the request');
            console.log(e)
            reject()
          }
        })
      })
    },

    async initializeLaunchDarkly(context, userId){
      const ldContext = {
        key: userId,
        kind: 'user',
        corp_id: context.state.corp_client_id,
      };
      const client = LDClient.initialize(process.env.VUE_APP_CLIENT_SIDE_ID, ldContext);
      await client.waitUntilReady();
      let allFlagsResult = client.allFlags();
      context.commit('update_feature_flags', allFlagsResult);
      return allFlagsResult;
    },

    updateQuestions (context, payload) {
      context.commit('update_app_error', false)
      context.commit('update_scroll_to_top', true)
      const regex = new RegExp('[\\?&]s=([^&#]*)');
      const results = regex.exec(location.search);
      const corporateShortcutURL = results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
      return new Promise((resolve, reject) => {
        axios.post(context.state.endpoints.all, {
          action: payload.action,
          step: payload.step,
          locale: payload.locale,
          hash: payload.hash,
          s: corporateShortcutURL,
        }).then(r => {
          if (r.data && r.data.success) {
            context.commit('update_questions', r.data.questions);
            resolve(r.data)
          } else {
            reject()
          }
        }).catch(e => {
          reject(e)
        })
      })
    },

    checkIntegrationService ({ state }, lookupKey) {
      axios.get(`${process.env.VUE_APP_INTEGRATION_SERVICE_URL}/userInfo/${lookupKey}`).catch(e => {
        console.log("error", e)
      })
    },
  },
  modules: {
    auth,
    localization,
    content
  }
})