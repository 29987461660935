<template>
    <div>
        <div v-if="error_account_in_use === ''">
            <div class="d-flex justify-content-center card-intro text-center">
                <div class="" style="width: 100%; max-width: 470px;">
                    <h1 class="text-32 text-center" data-contentful="body:wondr">
                        {{ $t('body:wondr', 'Start Wondr') }}
                    </h1>
                    <p data-contentful="body:description">
                        {{ $t('body:description', 'Learn how to eat your favorite foods to lose weight, sleep better, stress less, and live stronger.') }}
                    </p>
                </div>
            </div>
            <form novalidate="novalidate" class="form-floating-labels" @submit.stop.prevent="submitForm">
                <div class="d-flex justify-content-center card-form">
                    <div style="width: 100%; max-width: 300px;">
                        <div class="form-group centered-input" style="margin-bottom: 44px;">
                            <div>
                                <label for="field_email">{{ text_email_address }}</label>
                                <input
                                    type="email"
                                    id="field_email"
                                    class="form-control form-control-lg text-center"
                                    :data-placeholder="text_email_address"
                                    :placeholder="text_email_address"
                                    @keydown="fieldUpdated('email',$event)"
                                    :class=" { 'is-invalid' : !validation.email, 'fl-input': floatlabels !== null }"
                                    aria-describedby="Email"
                                    v-model='email'
                                    tabindex="1"
                                    @blur="checkEmail"
                                    ref="email"
                                    data-contentful="user_email"
                                >
                                <div class="form-text small text-warning email-suggestion" v-if="suggest!=''" data-contentful="body:do_you_mean">
                                    {{ $t('body:do_you_mean', 'Did you mean') }} <a href="#" @click.stop.prevent="useEmailSuggestion">{{suggest}}</a>?
                                    <button style="font-size:1.5rem;" type="button" class="close" @click="suggest=''">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div class="form-text small text-danger" v-if="email_feedback!=''" v-html="email_feedback"></div>
                            </div>
                        </div>
                        <p class="text-center text-14 mt-5 mb-5" data-contentful="body:already_have_account_text">
                            {{ $t('body:already_have_account_text', 'Already have an account?') }}
                            <a
                                href="/Login.html"
                                class="link-primary underline-link"
                                data-contentful="body:login_link_text"
                            >
                                {{ $t('body:login_link_text', 'Log in') }}
                            </a>
                        </p>
                        <p>
                            <button
                                type="submit"
                                tabindex="3"
                                class="btn btn-primary-light shadow btn-block"
                                v-html="next_button_content"
                                data-contentful="body:button_next"
                            />
                        </p>
                        <p class="mt-4"></p>
                        <div ref="googleLoginBtn" class="d-flex justify-content-center" />
                    </div>
                </div>
            </form>
        </div>
        <div v-else>

            <div class="d-flex justify-content-center card-intro text-center">
                <div class="" style="width: 100%; max-width: 470px;">

                    <div class="text-center mt-2 mb-4" v-html="error_account_in_use"></div>

                    <div class="text-center mb-2" style="margin-top:3rem;">
                        <h1 class="text-32 text-center" data-contentful="body:enroll_new_user">
                            {{ $t('body:enroll_new_user', 'Enroll as a new user') }}
                        </h1>
                    </div>

                    <div class="mt-4 mb-2 text-left">
                        <ol>
                            <li data-contentful="body:google_logout" v-html="google_logout_content"/>
                            <li data-contentful="body:login_to_new_gmail">
                                {{ $t('body:login_to_new_gmail', 'Log in to a different Gmail account.') }}
                            </li>
                            <li data-contentful="body:start_enrollment_with_google">
                                {{ $t('body:start_enrollment_with_google', 'Begin a new Wondr enrollment and click the "Sign in with Google" button.') }}
                            </li>
                        </ol>
                    </div>

                    <div class="row justify-content-center text-center mt-4">
                        <button
                            type="button"
                            class="btn btn-block btn-no-shadow text-uppercase text-spacing-4 btn-white"
                            @click="startNewEnrollment"
                            data-contentful="body:start_enrollment"
                        >
                          {{ $t('body:start_enrollment', 'Start new enrollment') }}
                        </button>
                    </div>

                </div>
            </div>
        </div>
    </div>

</template>

<script>
    import axios from 'axios';
    import email_suggestions from '@/helpers.js';
    import mixins from '@/mixins';
    export default {
        mixins: [mixins],
        data(){
            return {
                loading: false,
                email: this.getEmailValue(this.$store.state.saved_email),
                validation:{
                    email: true,
                    email_verification: true
                },
                floatlabels: '',
                suggest: '',
                email_feedback: '',
                error_message: '',
                error_account_in_use: '',
                email_in_use: ''
            }
        },
        watch: {
            '$store.state.saved_email'(newEmail) {
                this.email = this.getEmailValue(newEmail);
            }
        },
        created(){
            this.$eventHub.$on('appInitialized', r => {
                if (r.questions[0].value!=""){
                    this.email = r.questions[0].value;
                    this.$refs.email.focus();
                }
            })
        },
        mounted(){
            window.location.hash = "10.10";
            this.floatlabels = new FloatLabels( 'form.form-floating-labels', { exclude: '.no-floating-label' });

            google.accounts.id.initialize({
              client_id: Google_login_client_id,
              callback: this.handleCredentialResponse,
            });
            google.accounts.id.renderButton(this.$refs.googleLoginBtn, {});
            google.accounts.id.prompt();
        },
        computed: {
            text_email_address(){
                const textValue = this.getQuestionValue('user_email')
                return textValue ? textValue : "Email Address";
            },
            next_button_content(){
                return this.loading
                    ? `<img src="${VUE_APP_LMS}/v4/imgD/spinner-small-white.svg" style="height: 1rem;">`
                    : this.$t('body:button_next', 'Next');
            },
            text_above_questions(){
                return this.first_question ? this.first_question.textAboveQuestions : "";
            },
            text_below_questions(){
                return this.first_question ? this.first_question.textBelowQuestions : "";
            },
            first_question(){
                return this.questions.length ? this.questions[0] : null;
            },
            title(){
                return this.first_question ? this.first_question.pageTitle : "";
            },
            questions(){
                return this.$store.state.questions;
            },
            google_logout_content(){
                return this.$t(
                    'body:google_logout',
                    `<a href="https://accounts.google.com/Logout" rel="noopener noreferrer" target="_blank" style="text-decoration:underline;"> Log out </a> of Gmail account :google_email.`, {
                    replace: {
                        ':google_email': this.email_in_use,
                    },
                });
            }
        },
        methods: {
            getEmailValue(value){
                return sessionStorage.getItem('field_email') ? sessionStorage.getItem('field_email') : value;
            },
            isRetail(){
                let url = window.location.href;
                let regex = new RegExp('[?&]s(=([^&#]*)|&|#|$)');
                let results = regex.exec(url);
                if (!results) return false;
                if (!results[2]) return false;
                return decodeURIComponent(results[2].replace(/\+/g, ' '))==='Retail';
            },
            startNewEnrollment(){
                axios.get('/a/KDpg_logout.html').then(r => {
                    console.log(r);
                    location.reload(true);
                });
            },
            useEmailSuggestion(){
                this.email = this.suggest;
                this.suggest = '';
            },
            checkEmail(){

                // check for a valid email address
                var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                if ((this.email!='')&&(!re.test(String(this.email).toLowerCase()))){
                    this.validation.email = false;
                    this.email_feedback = "Please enter a valid email address";
                    return;
                }

                // check for suggestions
                let suggest = email_suggestions(this.email);
                if (suggest){
                    this.suggest = suggest;
                }

            },
            fieldUpdated(field,e){
                this.validation[field] = true;
                if (field=='email'){
                    this.suggest = '';
                    this.email_feedback = '';
                    if (e.key===' ') {
                        e.preventDefault();
                    }
                }
            },
            validateForm(){
                let ret = true;
                var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                let valid = re.test(String(this.email).toLowerCase());
                if ((this.email=='')||(!valid)){
                    this.validation.email = false;
                    ret = false;
                }
                return ret;
            },
            submitForm(){
                if (!this.validateForm()) return;
                this.loading = true;

                // checks for used emails with the backend
                axios.post(this.$store.state.endpoints.check_username, {
                    user_email: this.email
                }).then( r => {
                    if (r.data && r.data.success === true){
                        // console.log('Email doesn't exists: create account');
                        this.$store.commit('save_email',this.email);
                        this.$store.commit('update_use_email_as_username',true);
                        this.changeStep(10.1);
                    } else {
                        this.validation.email = false;
                        this.email_feedback = 'Please try again';
                    }
                }).catch( e => {
                    if (e.response.data.errorMessage === 'You must login to use that email address.'){
                        // console.log('Email already exists: log in');
                        this.$store.commit('save_email',this.email);
                        this.$store.commit('update_use_email_as_username',true);
                        this.$store.commit('multiple_accounts', e.response.data.multiple_accounts);
                        this.changeStep(10.2);
                    } else {
                        this.validation.email = false;
                        this.email_feedback = e.response.data.errorMessage;
                    }
                }).finally( () => {
                    this.loading = false;
                });
            },
            changeStep(val){
                this.$store.commit('change_step',val);
            },
            handleCredentialResponse(googleUser){
                $.ajax({
                    type: "POST",
                    dataType: "json",
                    url: "/Google_Token_Validate.html?page=/SEPv4",
                    data: {
                        "idtoken": googleUser.credential
                    },
                    contentType: "application/x-www-form-urlencoded; charset=utf-8",
                    success: function(json){
                        if(json.error > 0 && json.error_msg=='Bad Request. User has already completed enrollment.') {
                            if (json.join_date!='') {
                                this.error_account_in_use = `<strong>${json.email}</strong> is already associated with <strong>${json.full_name}</strong> who enrolled on <strong>${json.join_date}</strong>.`;
                            } else {
                                this.error_account_in_use = `<strong>${json.email}</strong> is already associated with <strong>${json.full_name}</strong>.`;
                            }
                            this.email_in_use = json.email;
                        } else if (json.redirect_url=='self'){
                            // Means we are authenticated
                            window.location.reload();
                        }
                    }
                });
                return false;
            }
        }
    }
</script>

<style type="text/css" scoped>
    .email-suggestion{
        padding: 0.25rem 0.5rem;
        font-size: 0.9rem;
    }
    .warning-input{
        border-color: #bf9a44;
    }
    .warning-label{
        color: #bf9a44 !important;
    }
    .underline-link {
      text-decoration: underline;
    }
</style>
