<template>
  <div>
      <account key="g1" v-if="current_step==10"/>
      <account-creation key="g1" v-if="current_step==10.1"/>
      <account-login key="g1" v-if="current_step==10.2"/>
      <question key="g3" v-if="current_step>10.5 && current_step<40"/>
      <confirmation key="g4" v-if="current_step==40"/>
      <block-card-factory
        key="g5"
        v-if="enabledSepContent"
        :step="current_step"
        :childProps="this.stepBlockConfig"
        class="mt-3 mt-md-5"
      />
  </div>
</template>

<script>
  import Account from '@/components/Account';
  import AccountCreation from '@/components/AccountCreation';
  import AccountLogin from '@/components/AccountLogin';
  import Question from '@/components/Question';
  import Confirmation from '@/components/Confirmation';
  import BlockCardFactory from '@/components/form-bloks/BlockCardFactory.vue';
  import { showZendeskChatBot, hideZendeskChatBot, closeZendeskChatBot } from '@/helpers/zendesk-chatbot-handler';
  import mixins from '@/mixins';
  import { mapGetters } from 'vuex';
  export default {
    mixins: [mixins],
    components: {
      Account,
      AccountCreation,
      AccountLogin,
      Question,
      Confirmation,
      BlockCardFactory,
    },
    data(){ 
      return {
        stepsWithDelayedChatBot: ['20.40', '40'],
        stepBlockConfig: {},
      }
    },
    computed: {
      ...mapGetters('content', ['getBlockEntries']),
      enabledSepContent(){
        return this.$store.state.featureFlags['enable-sep-content-block-web'];
      },
      current_step(){
        return this.$store.state.step;
      },
    },
    methods: {
      handleChatBotDisplay(currentStep) {
        closeZendeskChatBot();
        const locale = this.getLocaleValue();
        const shouldShowChatBotWithDelay = this.stepsWithDelayedChatBot.includes(currentStep);
        const isSpanishLocalizationEnabled = this.enableSpanishLocalization();
        const chatBotLocale = isSpanishLocalizationEnabled ? locale : null;
        showZendeskChatBot(chatBotLocale, shouldShowChatBotWithDelay);
      },
      handlePrePopulateEmailAddress() {
        const emailAddress = this.getUrlParam('e').replace(/ /g, '+');
        const isPrePopulateEmailAddressEnabled = this.enablePrePopulateEmailAddress();
        const emailValue = isPrePopulateEmailAddressEnabled && emailAddress ? emailAddress : '';
        this.$store.commit('save_email', emailValue);
      },
      handleFeatureFlagChange(newFeatureFlags, oldFeatureFlags) {
        const isSpanishLocalizationFlagChanged =
          newFeatureFlags['enable-spanish-localization'] !== oldFeatureFlags['enable-spanish-localization'];
        if (isSpanishLocalizationFlagChanged) {
          this.handleChatBotDisplay();
        }
        const isPrePopulateEmailAddressFlagChanged =
            newFeatureFlags['enable-prepopulate-email-address'] !== oldFeatureFlags['enable-prepopulate-email-address'];
        if (isPrePopulateEmailAddressFlagChanged) {
          this.handlePrePopulateEmailAddress();
        }
      },
      setStepBlock() {
        const baseStep = this.current_step.toString().split('.')[0];
        this.stepBlockConfig = this.getBlockEntries[this.current_step.toString()] || this.getBlockEntries[baseStep];
      },
    },
    watch: {
      '$store.state.featureFlags': 'handleFeatureFlagChange',
      '$store.state.content.blockEntries': 'setStepBlock',
      '$store.state.step'(step) {
        this.setStepBlock();
        this.handleChatBotDisplay(step);
      },
    },
    beforeDestroy() {
        hideZendeskChatBot();
    }
}
</script>