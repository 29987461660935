import { SEP_BLOCK_CONTENT_TYPE } from '@/helpers/constants';

function adaptBlockEntry(entry, includes) {
  const fields = entry.fields || {};
  const assets = includes.Asset || [];
  const blockImageId = fields.blockImage.sys.id;
  const imageUrl = blockImageId
    ? 'https:' + (assets.find((asset) => asset.sys.id === blockImageId).fields.file.url || '')
    : '';

  return {
    identifier: fields.identifier,
    title: fields.blockTitle,
    description: fields.blockDescription,
    backgroundColor: fields.blockBackgroundColor,
    image: imageUrl,
  };
}

export async function getBlockContentfulEntries({ rootState, commit }, { locale, contentful }) {
  const currentLocale = locale || 'en-US';
  const rewardsConfig =
    VUE_APP_REWARDS_OPT_INT === 'OPT-OUT' ? 'Opted out' :
    VUE_APP_REWARDS_OPT_INT === 'OPT-IN' && VUE_APP_DISABLE_POINTS === 'Y' ? 'Opted in - points disabled' :
    VUE_APP_REWARDS_OPT_INT === 'OPT-IN' && VUE_APP_DISABLE_POINTS !== 'Y' ? 'Opted in - points enabled' : '';

  try {
    const { items, includes } = await contentful.getEntries({
      'locale': currentLocale,
      'content_type': SEP_BLOCK_CONTENT_TYPE,
      'fields.callout[in]': rewardsConfig,
    });

    const entries = items.reduce((acc, entry) => {
      const identifier = entry.fields.identifier.toLowerCase();
      if (identifier) {
        acc[identifier] = adaptBlockEntry(entry, includes);
      }
      return acc;
    }, {});

    commit('setBlockEntries', entries);
  } catch (err) {
    commit('setBlockEntries', {});
    commit('setBlockError', err);
  }
}

