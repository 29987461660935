<template>
  <div v-if="identifier" class="container block-width-container px-1 px-md-3">
    <div
      v-if="backgroundColor"
      class="row align-items-center py-2 text-center text-md-left block-radio-container"
      :style="{ backgroundColor: backgroundColor }"
    >
      <div v-if="image" class="col-12 col-md-4 d-flex justify-content-center">
        <div>
          <img
            :src="image"
            alt="Wondr Bock Step Image"
            class="block-image"
            :class="specialImageClass"
          />
        </div>
      </div>
      <div v-if="title" class="col-12 col-md-8">
        <h2 class="step-block-title">{{ title }}</h2>
        <div v-if="description" v-html="description" class="step-block-description"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    identifier: {
      type: String,
      required: false,
    },
    title: {
      type: String,
      required: false,
    },
    description: {
      type: String,
      required: false,
    },
    image: {
      type: String,
      required: false,
    },
    backgroundColor: {
      type: String,
      required: false,
    },
    specialImageClass: {
      type: String,
      required: false,
    },
  },
};
</script>

<style scoped>
  .block-width-container {
    max-width: 91%;
  }
  .block-radio-container {
    border-radius: 12px;
  }
</style>
